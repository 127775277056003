import { Button, Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import sum from './sum.png';

export const Home: FC = () => {
	const handleDownload = () => {
		window.open(sum);
	};

	return (
		<Container
			maxWidth='md'
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<Typography variant='h6'>Liebe Sarah, Lieber Martin,</Typography>
			<Typography>wir wünschen euch alles gute zu eurer Hochzeit.</Typography>
			<Typography>Liebe Grüße,</Typography>
			<Typography>Lara und Paul</Typography>
			<img src={sum} alt='test' style={{ width: '100%', margin: '16px 0px' }} />
			<Box alignSelf='center'>
				<Button
					variant='contained'
					size='large'
					fullWidth={false}
					onClick={handleDownload}
				>
					Bild Downloaden
				</Button>
			</Box>
		</Container>
	);
};
